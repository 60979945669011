//Requires all logos from @/assets/img/customers

var requireCustomerLogos = require.context('../assets/img/customers', false);
var customerLogos = requireCustomerLogos.keys().reduce((p, c) => {
	let lastDotIndex = c.lastIndexOf('.');
	let customerCode = c.substring(2, lastDotIndex);
	p[customerCode] = requireCustomerLogos(c);
	return p;
}, {});

export default customerLogos;