const unitType = [{
	'id': '5b377291-ba4c-4321-beaf-871482e50fa6',
	'code': 'BG',
	'description': 'Bag',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BG - Bag'
}, {
	'id': '76e81af1-7694-4343-b76a-bd62fc58380f',
	'code': 'BA',
	'description': 'Barrel',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BA - Barrel'
}, {
	'id': '8a98bf23-96c5-4c55-a8fe-b58f434edb91',
	'code': 'BK',
	'description': 'Basket',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BK - Basket'
}, {
	'id': 'f35fbdc6-3102-4f1b-92c2-182a076852c8',
	'code': 'BB',
	'description': 'Big box',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BB - Big box'
}, {
	'id': 'c15c34cd-3016-49ff-8793-257c868190a4',
	'code': 'BP',
	'description': 'Bison pallet',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BP - Bison pallet'
}, {
	'id': '971b2985-bed6-419c-9d0d-3d76fe462412',
	'code': 'BO',
	'description': 'Bottle',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BO - Bottle'
}, {
	'id': '9834286f-a899-46b2-8f19-f21e1330fc6c',
	'code': 'BX',
	'description': 'Box',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BX - Box'
}, {
	'id': '8ec653e6-e8c1-497e-8274-ee4ac0ac243e',
	'code': 'BJ',
	'description': 'Bucket',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BJ - Bucket'
}, {
	'id': '09a6aa9f-6496-4cf0-b456-a8df026e29d1',
	'code': 'BN',
	'description': 'Bunt',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'BN - Bunt'
}, {
	'id': 'eb069031-959e-463e-af4b-b3e1b912d00b',
	'code': 'CA',
	'description': 'Can',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'CA - Can'
}, {
	'id': 'cb86598d-c03c-4a26-ad7a-3dae0d688fdb',
	'code': 'CT',
	'description': 'Carton',
	'width': null,
	'height': null,
	'length': null,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'CT - Carton'
}, {
	'id': '8c47450d-bde3-4d89-81b4-d3ab7def6568',
	'code': 'CT',
	'description': 'Carton',
	'width': null,
	'height': null,
	'length': null,
	'weight': 0,
	'isPallet': false,
	'codeAndDescription': 'CT - Carton'
}, {
	'id': '6b9794e5-4b29-4441-b8a0-722ad858bce5',
	'code': 'CS',
	'description': 'Case ',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'CS - Case '
}, {
	'id': '02718ac0-f4de-416a-b2de-f27e4449e4f4',
	'code': 'CS',
	'description': 'Case big',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'CS - Case big'
}, {
	'id': '1f82bbd7-08fc-432f-a0df-452b56e1aa72',
	'code': 'CS',
	'description': 'Case special',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'CS - Case special'
}, {
	'id': 'f27901bc-d4c1-453e-a8f1-8cbb9e2517fb',
	'code': 'CU',
	'description': 'Container',
	'width': 3,
	'height': 2.4,
	'length': 6,
	'weight': 0,
	'isPallet': false,
	'codeAndDescription': 'CU - Container'
}, {
	'id': '0641f40e-9696-4e07-9233-ff7eccafad51',
	'code': 'CO',
	'description': 'Container',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'CO - Container'
}, {
	'id': 'bb739b8d-5f9e-457d-86cd-f38234254723',
	'code': 'DR',
	'description': 'Drum',
	'width': 1,
	'height': 1,
	'length': 1,
	'weight': 0,
	'isPallet': false,
	'codeAndDescription': 'DR - Drum'
}, {
	'id': '6024e174-2a08-41ec-85cd-ef97f60d8829',
	'code': 'EUR',
	'description': 'EUR-Pallet',
	'width': 1.2,
	'height': 1,
	'length': 0.8,
	'weight': 0,
	'isPallet': false,
	'codeAndDescription': 'EUR - EUR-Pallet'
}, {
	'id': 'a7522d19-06bb-4f09-b7f7-c92aad12e0d7',
	'code': 'PLL',
	'description': 'EUR-Pallet Low',
	'width': 1.2,
	'height': 0.5,
	'length': 0.8,
	'weight': 0,
	'isPallet': false,
	'codeAndDescription': 'PLL - EUR-Pallet Low'
}, {
	'id': '6e2df9cc-51c9-4aae-a7bb-bd32b0979c53',
	'code': 'EN',
	'description': 'Envelope',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'EN - Envelope'
}, {
	'id': 'dc23f294-7789-43bf-a25e-e560b754fe38',
	'code': 'FR',
	'description': 'Frame',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'FR - Frame'
}, {
	'id': '1de27564-0b3e-4cf6-95c6-b76c51f09d5a',
	'code': 'NT',
	'description': 'Net',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'NT - Net'
}, {
	'id': 'a6baa9fd-207b-4bfa-bca5-634e28624796',
	'code': 'PK',
	'description': 'Package',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'PK - Package'
}, {
	'id': '9bd5e3c2-3ccc-4a5a-bcbe-741646ba98e7',
	'code': 'PA',
	'description': 'Packet',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'PA - Packet'
}, {
	'id': '6a854085-e5b4-45c0-a0f7-7a4c53551c32',
	'code': 'PL',
	'description': 'Pallet',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'PL - Pallet'
}, {
	'id': '7afb72b9-2bd6-4500-a82e-efd2c792dd66',
	'code': 'PC',
	'description': 'Parcel',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'PC - Parcel'
}, {
	'id': 'e0e97a18-40ef-4e6d-9f6e-078da763f6cd',
	'code': 'TUB',
	'description': 'Plastic Tub',
	'width': 0.5,
	'height': 1,
	'length': 2,
	'weight': 0,
	'isPallet': false,
	'codeAndDescription': 'TUB - Plastic Tub'
}, {
	'id': 'be13c128-9d90-4fcd-b9a5-57e86c902967',
	'code': 'SA',
	'description': 'Sack',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'SA - Sack'
}, {
	'id': '5ed53ae3-005a-48b7-8236-9f98e62f5902',
	'code': 'SU',
	'description': 'Suitcase',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'SU - Suitcase'
}, {
	'id': '17c66560-5224-4286-9deb-c97710b0c147',
	'code': 'TK',
	'description': 'Tank rectangular',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'TK - Tank rectangular'
}, {
	'id': 'c16cbfc2-6239-47a1-a053-1f1d149a3999',
	'code': 'TN',
	'description': 'Tin',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'TN - Tin'
}, {
	'id': 'c2c107ca-05bf-4dbd-af49-152113d7351a',
	'code': 'TR',
	'description': 'Trunk',
	'width': 0,
	'height': 0,
	'length': 0,
	'weight': null,
	'isPallet': false,
	'codeAndDescription': 'TR - Trunk'
}];

module.exports = unitType;