export default {
	no: {
		tripNumber: 'Turnummer',
		driver: 'Sjåfør',
		phoneNumber: 'Telefonnummer',
		etd: 'Etd',
		etdTitle: 'Estimert avgangstid',
		eta: 'Eta',
		etaTitle: 'Estimert ankomsttid',
		transporter: 'Transportør',
		fromAddress: 'Fra adresse',
		toAddress: 'Til adresse',
		actions: 'Handlinger',
		saveNewTrip: 'Lagre ny tur',
		clear: 'Nullstill',
		saveTrip: 'Lagre tur',
		deleteTrip: 'Slett tur',
		etdFrom: 'ETD: Fra...',
		etdTo: 'Til...',
		etaFrom: 'ETA: Fra...',
		etaTo: 'Til...',
		goToToday: 'I dag',
		clearSelection: 'Nullstill',
		close: 'Lukk',
		selectMonth: 'Velg måned',
		selectYear: 'Velg år',
		selectDecade: 'Velg tiår',
		noMatchingOptions: 'Beklager, ingen treff.',
		selectDriver: 'Velg sjåfør',
		email: 'epost',

		orderNumber: '',
		waybillNumber: '',
		trips: 'Trips',
		orders: 'Orders',
		switchEnglish: 'Switch to English',
		switchNorwegian: 'Bytt til norsk',


		'orderStatus/deleted': 'Slettet',
		'orderStatus/draft': 'Kladd',
		'orderStatus/delivered': 'Levert',
		'orderStatus/tender': 'Anbud',
		'orderStatus/awaitingEstimatedPrice': 'Avventer estimert pris',
		'orderStatus/awaitingEstimatedApproval': 'Avventer estimert godkjenning',
		'orderStatus/accepted': 'Akseptert',
		'orderStatus/approved': 'Godkjent',
		'orderStatus/awaitingPrice': 'Avventer pris',
		'orderStatus/awaitingApproval': 'Avventer godkjenning',
		'orderStatus/refused': 'Ikke akseptert',
		'orderStatus/externalSent': 'Sendt',
		'orderStatus/externalFailed': 'Sending feilet',
		'orderStatus/externalReceived': 'Ekstern',


		'orderCollectionStatus/deleted': 'Slettet',
		'orderCollectionStatus/draft': 'Kladd',
		'orderCollectionStatus/delivered': 'Levert',
		'orderCollectionStatus/tender': 'Anbud',
		'orderCollectionStatus/awaitingEstimatedPrice': 'Avventer estimert pris',
		'orderCollectionStatus/awaitingEstimatedApproval': 'Avventer estimert godkjenning',
		'orderCollectionStatus/accepted': 'Akseptert',
		'orderCollectionStatus/approved': 'Godkjent',
		'orderCollectionStatus/awaitingPrice': 'Avventer pris',
		'orderCollectionStatus/awaitingApproval': 'Avventer godkjenning',
		'orderCollectionStatus/refused': 'Ikke akseptert',
		'orderCollectionStatus/externalSent': 'Sendt',
		'orderCollectionStatus/externalFailed': 'Sending feilet',
		'orderCollectionStatus/externalReceived': 'Ekstern',
	},
	en: {
		actions: 'Actions',
		clear: 'Clear',
		clearSelection: 'Clear selection',
		close: 'Close',
		deleteTrip: 'Delete Trip',
		driver: 'Driver',
		email: 'Email',
		eta: 'ETA',
		etaFrom: 'ETA: From...',
		etaTitle: 'Estimated Time of Arrival',
		etaTo: 'To...',
		etd: 'ETD',
		etdFrom: 'ETD: From...',
		etdTitle: 'Estimated Time of Departure',
		etdTo: 'To...',
		fromAddress: 'From Address',
		goToToday: 'Go to today',
		noMatchingOptions: 'Sorry, no matching options.',
		orderNumber: 'Order Number',
		orders: 'Orders',
		phoneNumber: 'Phone Number',
		saveNewTrip: 'Save New Trip',
		saveTrip: 'Save Trip',
		selectDecade: 'Select Decade',
		selectDriver: 'Select driver',
		selectMonth: 'Select Month',
		selectYear: 'Select Year',
		toAddress: 'To Address',
		transporter: 'Transporter',
		tripNumber: 'Trip Number',
		trips: 'Trips',
		waybillNumber: 'Waybill Number',
		switchEnglish: 'Switch to English',
		switchNorwegian: 'Bytt til norsk',

		'orderStatus/deleted': 'Deleted',
		'orderStatus/draft': 'Draft',
		'orderStatus/delivered': 'Delivered',
		'orderStatus/tender': 'Tender',
		'orderStatus/awaitingEstimatedPrice': 'Awaiting estimated price',
		'orderStatus/awaitingEstimatedApproval': 'Awaiting estimated approval',
		'orderStatus/accepted': 'Accepted',
		'orderStatus/approved': 'Approved',
		'orderStatus/awaitingPrice': 'Awaiting price',
		'orderStatus/awaitingApproval': 'Awaiting approval',
		'orderStatus/refused': 'Refused',
		'orderStatus/canceled': 'Canceled',
		'orderStatus/externalSent': 'Sent',
		'orderStatus/externalFailed': 'Sending failed',
		'orderStatus/externalReceived': 'External',


		'orderCollectionStatus/deleted': 'Deleted',
		'orderCollectionStatus/draft': 'Draft',
		'orderCollectionStatus/delivered': 'Delivered',
		'orderCollectionStatus/tender': 'Tender',
		'orderCollectionStatus/awaitingEstimatedPrice': 'Awaiting estimated price',
		'orderCollectionStatus/awaitingEstimatedApproval': 'Awaiting estimated approval',
		'orderCollectionStatus/accepted': 'Accepted',
		'orderCollectionStatus/approved': 'Approved',
		'orderCollectionStatus/awaitingPrice': 'Awaiting price',
		'orderCollectionStatus/awaitingApproval': 'Awaiting approval',
		'orderCollectionStatus/refused': 'Refused',
		'orderCollectionStatus/canceled': 'Canceled',
		'orderCollectionStatus/externalSent': 'Sent',
		'orderCollectionStatus/externalFailed': 'Sending failed',
		'orderCollectionStatus/externalReceived': 'External',
	}
};
