import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf';
import PdfjsWorker from 'worker-loader?esModule=false!pdfjs-dist/es5/build/pdf.worker.entry';

if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjsLib.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}

export function documentToDataUrl(b64d) {
	return new Promise((resolve, reject) => {
		pdfjsLib.getDocument({data: b64d}).promise.then( pdf => {
			pdf.getPage(1).then( page => {
				var scale = 0.22;
				var viewport = page.getViewport({scale});
				//
				// Prepare canvas using PDF page dimensions
				//
				var canvas = document.createElement('canvas');
				var context = canvas.getContext('2d');
				canvas.height = viewport.height;
				canvas.width = viewport.width;
				//
				// Render PDF page into canvas context
				//
				var task = page.render({canvasContext: context, viewport: viewport});
				task.promise.then(() => {
					let dataURL = canvas.toDataURL('image/png');
					resolve(dataURL);
				});
			});
		}).catch( error => {
			console.error(error);
			reject(error);
		});
	});
}
