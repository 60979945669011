
export default [
	{
		path: '/',
		name: 'orders',
		component: () => import(/* webpackChunkName: "orders" */ './orders/index.vue'),
		meta: {
			public: false
		}
	},
	{
		path: '/orders/:orderId',
		component: () => import(/* webpackChunkName: "orders" */ './orders/index.vue'),
		meta: {
			public: false
		}
	},
	{
		path: '/orderCollections',
		name: 'orderCollections',
		component: () => import(/* webpackChunkName: "orderCollections" */ './orderCollections/index.vue'),
		meta: {
			public: false
		}
	},
	{
		path: '/orderCollections/:orderCollectionId',
		component: () => import(/* webpackChunkName: "orderCollections" */ './orderCollections/index.vue'),
		meta: {
			public: false
		}
	},
	{
		path: '/admin',
		name: 'admin',
		component: () => import(/* webpackChunkName: "orders" */ './login/admin.vue'),
		meta: {
			public: false
		}
	},
];
