import { createNamespacedHelpers } from 'vuex';
import BaseStore from '@/store';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
	currentUser: {
		uid: null,
		email: null,
		displayName: null,
		photoUrl: null,
	},
	transporterSettings: null,
	isLoggedIn: false,
	hasAccess: false,
	isVerified: false,
	isSuper: false,
	lastLogin: null,
	transporterCode: localStorage.getItem('transporterCode') || null,
	usersWithoutAccess: [],
	usersWithAccess: [],
	bannedUsers: [],
	superUsers: [],
	updateUsersWithoutAccessIntervalId: null,
	companies: [],
};

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

BaseStore.registerModule('user', store);

export default BaseStore;

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('user');

export {
	mapState,
	mapGetters,
	mapMutations,
	mapActions
};