import tzDate from './tzDate';
import createPatch from './createPatch';
import negotiateTranslatedText from './dictionary/negotiateTranslatedText';
import request from './request';
import publicRequest from './publicRequest';
import userAuth from './userAuth';
import isDevelopment from './isDevelopment';
import newUUID from 'uuid/v4';
import delay from './delay';
import customerLogos from './customerLogos';
import * as pdfCore from './pdfCore';

export * from 'shared';

const baseUrl = () => {
	const transporterCode = localStorage.getItem('transporterCode');
	if(!transporterCode) throw new Error('No Transportercode');
	if(isDevelopment()){
		return `http://localhost:6060/v1/${transporterCode}`; //dev
		// return `http://localhost:6060/v1/${transporterCode}`; //dev
	} else {
		var rootHost = window.location.host;
		return `https://api.${rootHost}/v1/${transporterCode}`; //prod
	}
};

const publicBaseUrl = () => {
	if(isDevelopment()){
		return 'http://localhost:6060/public/v1/'; //dev
		// return 'http://localhost:6060/public/v1/'; //dev
	} else {
		var rootHost = window.location.host;
		return `https://api.${rootHost}/public/v1/`; //prod
	}
};

export {
	baseUrl,
	publicBaseUrl,
	tzDate,
	createPatch,
	negotiateTranslatedText,
	request,
	publicRequest,
	userAuth,
	isDevelopment,
	newUUID,
	delay,
	customerLogos,
	pdfCore
};
