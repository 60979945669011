const getUserName = function(state) {
	return state.currentUser.displayName;
};
const getUserHasAdminAccess = function(state){
	// return (state.isVerified && state.hasAccess);
	return (state.isVerified && state.hasAccess && state.isSuper);
};

const hasUsersRequestingAccess = function(state){
	return !!state.usersWithoutAccess.length;
};

const numberOfUsersRequestingAccess = function(state){
	return state.usersWithoutAccess.length;
};

const isSupportUser = function(state){
	return state.currentUser.email === 'support@timpex.no';
};

export default {
	getUserName,
	getUserHasAdminAccess,
	hasUsersRequestingAccess,
	numberOfUsersRequestingAccess,
	isSupportUser
};
