import Vue from 'vue';
import Router from 'vue-router';
import routes from './modules';
// import {userAuth} from '@/core';
// import store from '@/store';

Vue.use(Router);

var router =  new Router({
	mode: 'history',
	routes
});

// router.beforeEach(async(to, from, next) => {
// 	if(to.meta && to.meta.public) {
// 		return next();
// 	}
// 	if(notLoggedIn){
// 		store.dispatch('user/clearUserStore');
// 		next({name: 'login'});
// 	} else if(notVerified){
// 		store.dispatch('user/clearUserStore');
// 		next({name: 'notVerified'});
// 	} else if(noAccess){
// 		store.dispatch('user/clearUserStore');
// 		next({name: 'noAccess'});
// 	} else {
// 		next();
// 	}
// });

export default router;
