const moment = require('moment-timezone');

function tzDate(arg) {
	let timeZone = arg.timeZone || 'Europe/Oslo';
	let dateString = (typeof(arg) === 'string') ? arg : (arg.date ? negotiateISO(arg.date) : negotiateISO(arg));
	dateString = moment(dateString).tz(timeZone).format('YYYY-MM-DD[T]HH:mm');
	let momentDate = moment(dateString).tz(timeZone);
	if (!momentDate.isValid())
		return;

	let hasTime = extractHasTime(arg);
	let format = 'YYYYMMDD0000';
	if (hasTime)
		format = 'YYYYMMDDHHmm';

	return {
		date: parseInt(momentDate.format(format), 10),
		hasTime: hasTime,
		timeZone: timeZone
	};
}

function extractHasTime(arg) {
	if ((typeof(arg) === 'object') && ('hasTime' in arg))
		return !!arg.hasTime;


	let dateString = (typeof(arg) === 'string') ? arg : (arg.date ? negotiateISO(arg.date) : negotiateISO(arg));
	return dateString.indexOf('T') > -1;
}

function negotiateISO(arg) {
	try {
		if (arg.toISOString)
			return arg.toISOString();
		return arg;
	} catch (e) {
		return '';
	}
}

export default tzDate;