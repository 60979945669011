<template>
	<div>
		<div><em>You do not have access to this system</em></div>
		<div v-if="!userAlreadyRequestedAccess" style="margin-top:15px;">
			<div>New to this system, and want access?</div>
			<div><b-btn @click="requestAccess">Request access</b-btn></div>
		</div>
		<div v-if="userAlreadyRequestedAccess" style="margin-top:15px;">
			<div>A request for access to this system has already been sent</div>
		</div>
	</div>
</template>

<script>
import { userAuth } from '@/core';
import { mapActions, mapMutations } from './store';

export default {
	name: 'noAccess',
	data() {
		return {
			intervalId: null,
			userAlreadyRequestedAccess: true,
		};
	},
	methods: {
		...mapActions([
			'userRequestAccess',
			'userHasRequestedAccess'
		]),
		...mapMutations([
			'setHasAccess'
		]),
		async waitForUserVerification() {
			this.hasAccess();
			this.intervalId = setInterval(() => this.hasAccess(true), 10000);
		},
		async hasAccess(noLog = false){
			let noAccess = await userAuth.userNoAccess(noLog);
			this.setHasAccess(!noAccess);
			if(!noAccess){
				this.setHasAccess(!noAccess);
				clearInterval(this.intervalId);
				// this.$router.replace({name:'orders'});
			}
		},
		async requestAccess() {
			await this.userRequestAccess();
			this.userAlreadyRequestedAccess = true;
		},
		async hasRequestedAccess() {
			this.userAlreadyRequestedAccess = await this.userHasRequestedAccess();
		}
	},
	async mounted() {
		this.waitForUserVerification();
		await this.hasRequestedAccess();
	},
	beforeDestroy() {
		clearInterval(this.intervalId);
	}
};
</script>

