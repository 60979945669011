
function statusValues(status){
	switch(status){
	case -15:
		return 'refused';
	case -10:
		return 'deleted';
	case -5:
		return 'canceled';
	case 0:
		return 'tender';
	case 5:
		return 'awaitingEstimatedPrice';
	case 6:
		return 'awaitingEstimatedApproval';
	case 10:
		return 'accepted';
	case 20:
		return 'awaitingPrice';
	case 25:
		return 'awaitingApproval';
	case 30:
		return 'approved';
	case 100:
	default:
		return 'delivered';
	}
}

statusValues.refused = -15;
statusValues.deleted = -10;
statusValues.canceled = -5;
statusValues.tender = 0;
statusValues.awaitingEstimatedPrice = 5;
statusValues.awaitingEstimatedApproval = 6;
statusValues.accepted = 10;
statusValues.awaitingPrice = 20;
statusValues.awaitingApproval = 25;
statusValues.approved = 30;
statusValues.delivered = 100;

module.exports = statusValues;
