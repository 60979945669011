import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './bootstrap.scss';
import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import VuejsDialog from 'vuejs-dialog';
import { userAuth } from '@/core';
import authConfig from './firebaseConfig.json';

Vue.use(VuejsDialog);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);

console.log('%cTimpex Transportportal', 'font-size:6.5vw;background-color:#425a70;color:white;padding:1em;');

userAuth.init(authConfig);
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
