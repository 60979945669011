import { request, publicRequest, userAuth } from '@/core';
import moment from 'moment-timezone';
import router from '@/router';

const setUserInfo = async function({commit}, payload){
	if(!payload){
		commit('setLoggedIn', false);
		commit('clearUser');
	} else {
		commit('setLoggedIn', true);
		commit('setUser', payload);
	}
};
const setUsersWithoutAccessInterval = function({dispatch, commit, state}){
	if(state.hasAccess && state.isVerified){
		dispatch('fetchUsersWithoutAccess');
	}
	if(state.updateUsersWithoutAccessIntervalId == null && state.isLoggedIn){
		let intervalId = setInterval( () => {
			if(state.hasAccess && state.isVerified){
				dispatch('fetchUsersWithoutAccess', {noLog:true});
			}
		}, 60000);
		commit('setUsersWithoutAccessIntervalId', intervalId);
	}
};
const clearUsersWithoutAccessInterval = function({commit, state}){
	clearInterval(state.updateUsersWithoutAccessIntervalId);
	commit('setUsersWithoutAccessIntervalId', null);
};


const userRequestAccess = async function(){
	await request({
		method: 'post',
		url: '/users/currentUser/requestAccess',
	});
};

const userHasRequestedAccess = async function(){
	return new Promise(resolve => {
		request({
			method: 'get',
			url: '/users/currentUser/hasRequestedAccess',
		}).then(response => {
			resolve(response);
		});
	});
};

const fetchUsersWithoutAccess = async function({commit}, payload = {noLog:false}){
	let users = await request({
		method: 'get',
		url: `/users/requiresAccess?noLog=${payload.noLog}`
	});
	commit('setUsersWithoutAccess', users);
};

const fetchUsersWithAccess = async function({commit}){
	let users = await request({
		method: 'get',
		url: '/users/hasAccess'
	});
	commit('setUsersWithAccess', users);
};

const fetchBannedUsers = async function({commit}){
	let users = await request({
		method: 'get',
		url: '/users/banned'
	});
	commit('setBannedUsers', users);
};

const fetchSuperUsers = async function({commit}){
	let users = await request({
		method: 'get',
		url: '/users/super'
	});
	commit('setSuperUsers', users);
};

const grantUsersAccess = async function({commit}, payload){
	if(payload.length > 1){
		await request({
			method: 'post',
			url: '/users/grantAccess',
			data: payload
		});
	} else {
		let userId = payload[0];
		await request({
			method: 'post',
			url: `/users/${userId}/grantAccess`,
		});
	}
	commit('movetoUsersWithAccess', payload);
};

const revokeUsersAccess = async function({commit}, payload){
	if(payload.length > 1){
		await request({
			method: 'post',
			url: '/users/revokeAccess',
			data: payload
		});
	} else {
		let userId = payload[0];
		await request({
			method: 'post',
			url: `/users/${userId}/revokeAccess`,
		});
	}
	commit('movetoUsersWithoutAccess', payload);
};

const banUsers = async function({commit}, payload){
	if(payload.length > 1){
		await request({
			method: 'post',
			url: '/users/ban',
			data: payload
		});
	} else {
		let userId = payload[0];
		await request({
			method: 'post',
			url: `/users/${userId}/ban`,
		});
	}
	commit('movetoBannedUsers', payload);
};

const upgradeUser = async function({commit}, payload){
	let userId = payload;
	await request({
		method: 'post',
		url: `/users/${userId}/upgrade`,
	});
	commit('movetoSuperusers', payload);
};

const downgradeUser = async function({commit}, payload){
	let userId = payload;
	await request({
		method: 'post',
		url: `/users/${userId}/downgrade`,
	});
	commit('movefromSuperusers', payload);
};

const setOrderHandler = async function({commit}, payload){
	let userId = payload;
	await request({
		method: 'post',
		url: `/users/${userId}/setOrderHandler`,
	});
	commit('setUserOrderHandler', payload);
};

const removeOrderHandler = async function({commit}, payload){
	let userId = payload;
	await request({
		method: 'post',
		url: `/users/${userId}/removeOrderHandler`,
	});
	commit('removeUserOrderHandler', payload);
};

const unbanUsers = async function({commit}, payload){
	if(payload.length > 1){
		await request({
			method: 'post',
			url: '/users/removeBan',
			data: payload
		});
	} else {
		let userId = payload[0];
		await request({
			method: 'post',
			url: `/users/${userId}/removeBan`,
		});
	}
	commit('movetoUsersWithoutAccessFromBanned', payload);
};

const deleteUsers = async function({commit}, payload){
	if(payload.length > 1){
		await request({
			method: 'delete',
			url: '/users',
			data: payload
		});
	} else {
		let userId = payload[0];
		await request({
			method: 'delete',
			url: `/users/${userId}`,
		});
	}
	commit('removeUserFromWithoutAccess', payload);
};

const clearUserStore = function({dispatch, commit}){
	commit('setLoggedIn', false);
	commit('setIsVerified', false);
	commit('setHasAccess', false);
	commit('setIsSuper', false);
	dispatch('setTransporter', null);
	commit('setCompanies', []);
	commit('setUsersWithAccess', []);
	commit('setUsersWithoutAccess', []);
	commit('clearTransporterCode');
};

const fetchCompanies = async function({commit}){
	let companies = await publicRequest({
		method: 'get',
		url: '/companies'
	});
	commit('setCompanies', companies);
};

const setTransporter = async function({commit}, transporterCode){
	if(!transporterCode){
		commit('setTransporterCode', null);
		commit('setTransporterSettings', null);
	} else {
		//Set transporterCode first, so that baseUrl works
		commit('setTransporterCode', transporterCode);

		let transporterSettings = await request({
			method: 'get',
			url: '/transporters/settings'
		});
		commit('setTransporterSettings', transporterSettings);
	}
};

const createUserWithFirebase = async function(context, payload){
	let userData = payload;
	let result = await request({
		method: 'post',
		url: '/users/createUser',
		data: userData
	});
	return result;
};

const generatePasswordResetLink = async function(context, payload){
	let userId = payload[0];
	let result = await request({
		method: 'post',
		url: `/users/${userId}/generatePasswordResetLink`,
	});
	return result;
};

const userLastLogin = async function({commit}) {
	try {
		var {lastLogin} = await userAuth.userLastLogin();
	} catch(e){
		//User not logged in
		return;
	}
	var newDateObj = {
		date: null,
		hasTime: true,
		timeZone: 'Europe/Oslo'
	};
	if(!lastLogin){
		commit('setLastLogin', newDateObj);
		userAuth.updateLastLogin();
		return;
	}
	var newDate = moment(lastLogin);
	let dateString = newDate.format('YYYYMMDDHHmm');
	newDateObj.date = parseInt(dateString, 10);
	commit('setLastLogin', newDateObj);
	userAuth.updateLastLogin();
};

const clearUserSettings = ({commit}) => {
	commit('clearUserDefinedColumnDefinitions');
};

const loadUserDefinedColumnDefinitions = async({dispatch, rootState}) => {
	let defName = 'colDef';
	let gridComponent = router.currentRoute.name;
	if(gridComponent === 'orderCollections'){
		defName = 'colDefOC';
	}
	var ttl = localStorage.getItem(`${defName}Ttl`);
	var expired = (new Date().valueOf() - ttl) >= 0;
	let valid, colDef;
	try {
		colDef = JSON.parse(localStorage.getItem(`${defName}`));
	} catch(e){
		//No need to handle
	}
	if(colDef){
		valid = rootState[gridComponent].gridOptions.columnApi.applyColumnState({state: colDef, applyOrder: true});
	}
	if(expired || !valid){
		let {colDef} = await dispatch('getUserDefinedColumnDefinitions');
		valid = undefined;
		if(colDef){
			// ttl = (new Date().valueOf()+(1000 * 60));
			ttl = (new Date().valueOf()+(1000 * 60 * 60));
			localStorage.setItem(`${defName}Ttl`, ttl);
			localStorage.setItem(`${defName}`, JSON.stringify(colDef));
			valid = rootState[gridComponent].gridOptions.columnApi.applyColumnState({state: colDef, applyOrder: true});
		}
		if(!valid){ //either not valid, or colDef = undefined
			dispatch('resetUserDefinedColumnDefinitions');
		}
	}
};
const setUserDefinedColumnDefinitions = async({dispatch, rootState}) => {
	let defName = 'colDef';
	let gridComponent = router.currentRoute.name;
	if(gridComponent === 'orderCollections'){
		defName = 'colDefOC';
	}
	// var ttl = (new Date().valueOf()+(1000 * 60));
	let ttl = (new Date().valueOf()+(1000 * 60 * 60));
	let colDef = rootState[gridComponent].gridOptions.columnApi.getColumnState();
	localStorage.setItem(`${defName}`, JSON.stringify(colDef));
	localStorage.setItem(`${defName}Ttl`, ttl);
	await dispatch('postUserDefinedColumnDefinitions', {columnDefinition: colDef});
};
const resetUserDefinedColumnDefinitions = async({dispatch, rootState}) => {
	let defName = 'colDef';
	let gridComponent = router.currentRoute.name;
	if(gridComponent === 'orderCollections'){
		defName = 'colDefOC';
	}
	localStorage.removeItem(`${defName}`);
	localStorage.removeItem(`${defName}Sort`);
	localStorage.removeItem(`${defName}Ttl`);

	rootState[gridComponent].gridOptions.columnApi.resetColumnState();

	await dispatch('postUserDefinedColumnDefinitions');
};
const getUserDefinedColumnDefinitions = async({rootState}) => {
	//Request user defined column definitions from server
	var userId = rootState.user.currentUser.uid;
	var url = `/users/${userId}/settings/columnDef`;
	if(router.currentRoute.name === 'orderCollections'){
		url = `/users/${userId}/settings/columnDefOC`;
	}
	var response;
	try{
		response = await request({
			method: 'get',
			url: url
		});
	} catch (e) {
		console.log(e.message);
	}
	return {
		colDef: response.columnDefinitions,
	};
};
const postUserDefinedColumnDefinitions = async({rootState}, payload = null) => {
	//Request user defined column definitions from server
	var userId = rootState.user.currentUser.uid;
	var url = `/users/${userId}/settings/columnDef`;
	if(router.currentRoute.name === 'orderCollections'){
		url = `/users/${userId}/settings/columnDefOC`;
	}
	var response;
	try{
		response = await request({
			method: 'post',
			url: url,
			data: payload
		});
	} catch (e) {
		console.log(e.message);
	}
	return {
		colDef: response.columnDefinition,
	};
};

export default {
	setUserInfo,
	setUsersWithoutAccessInterval,
	clearUsersWithoutAccessInterval,
	userRequestAccess,
	userHasRequestedAccess,
	fetchUsersWithoutAccess,
	fetchUsersWithAccess,
	fetchBannedUsers,
	fetchSuperUsers,
	grantUsersAccess,
	revokeUsersAccess,
	banUsers,
	unbanUsers,
	upgradeUser,
	downgradeUser,
	deleteUsers,
	clearUserStore,
	fetchCompanies,
	setTransporter,
	createUserWithFirebase,
	generatePasswordResetLink,
	userLastLogin,
	clearUserSettings,
	loadUserDefinedColumnDefinitions,
	setUserDefinedColumnDefinitions,
	resetUserDefinedColumnDefinitions,
	getUserDefinedColumnDefinitions,
	postUserDefinedColumnDefinitions,
	setOrderHandler,
	removeOrderHandler,
};
