<template>
	<div id="app">
		<b-navbar toggleable="md" type="dark">
			<b-navbar-brand>
				<img src="./assets/img/logo.png" alt="Timpex" class="align-top" height="27" style="margin-top:-.375rem;">
				<span style="margin:auto .375rem;">Transportportal</span>
			</b-navbar-brand>
			<b-navbar-nav id="nav" class="ml-auto mr-auto router">
				<b-nav-item v-if="isLoggedIn" :to="{name: 'orders'}">Orders</b-nav-item>
				<b-nav-item v-if="isLoggedIn" :to="{name: 'orderCollections'}">TransportNeed</b-nav-item>
			</b-navbar-nav>
			<b-navbar-nav class="ml-auto mr-auto settings">
				<!-- <b-nav-item v-if="!isLoggedIn" :to="{name: 'login'}">Login</b-nav-item> -->
				<company-combobox v-if="isLoggedIn"/>
				<b-nav-item-dropdown v-if="isLoggedIn" right>
					<!-- Using button-content slot -->
					<template slot="button-content">
						<font-awesome-icon class="fa-lg" icon="user-cog" />
						<em style="margin:0 10px;">User settings</em>
					</template>
					<b-dropdown-item @click="saveColumnDefsOnUser">Save column settings</b-dropdown-item>
					<b-dropdown-item @click="resetColumnDefsOnUser">Reset columns</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown v-if="isLoggedIn" right>
					<!-- Using button-content slot -->
					<template slot="button-content">
						<font-awesome-layers>
							<font-awesome-icon v-if="hasUsersRequestingAccess" class="fa-xs indicationCircle" icon="circle" />
							<font-awesome-icon class="fa-lg" icon="user-circle" />

						</font-awesome-layers>
						<em style="margin:0 10px;" v-text="getUserName"></em>
					</template>
					<b-dropdown-item :to="{name: 'admin'}" v-if="getUserHasAdminAccess">
						Admin 
						<b-badge pill class="float-right" style="margin-top:2px" variant="info" v-text="numberOfUsersRequestingAccess" v-if="hasUsersRequestingAccess" />
					</b-dropdown-item>
					<b-dropdown-item @click="logout">Log out</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-navbar-nav>
		</b-navbar>
		<access-requirements class="mainContainer">
			<router-view />
		</access-requirements>
	</div>
</template>

<script>
import { userAuth } from '@/core';
import { mapState, mapGetters, mapActions } from './modules/login/store';

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import AccessRequirements from './modules/login/accessRequirements.vue';
import CompanyCombobox from './modules/login/companyCombobox.vue';

library.add(fas);

export default {
	name: 'App',
	components: {
		'font-awesome-icon': FontAwesomeIcon,
		'font-awesome-layers': FontAwesomeLayers,
		AccessRequirements,
		CompanyCombobox,
	},
	computed: {
		...mapState([
			'isLoggedIn',
		]),
		...mapGetters([
			'getUserName',
			'getUserHasAdminAccess',
			'hasUsersRequestingAccess',
			'numberOfUsersRequestingAccess',
		])
	},
	async created() {
		userAuth.onUserStateChanged(async(user) => {
			this.setUserInfo(user);
		});
	},
	methods: {
		...mapActions([
			'setUserInfo',
			'clearUsersWithoutAccessInterval',
			'clearUserStore',
			'clearUserSettings',
			'setUserDefinedColumnDefinitions',
			'resetUserDefinedColumnDefinitions',
		]),
		logout() {
			userAuth.signOut();
			this.clearUserStore();
			this.clearUserSettings();
		},
		saveColumnDefsOnUser(){
			this.setUserDefinedColumnDefinitions();
		},
		resetColumnDefsOnUser(){
			this.resetUserDefinedColumnDefinitions();
		},
	}
};
</script>


<style lang="scss">
#app {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
:focus {
	outline: none;
}
svg.indicationCircle {
	font-size:0.55rem;
	color: rgba(255,193,7, 1);
    top: -17px !important;
    right: -20px !important;
    z-index: 1;
}
.navbar {
	background-color: #425a70;
}
.navbar-nav.router,
.navbar-nav.settings {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
	margin-bottom: auto;
	flex-wrap: wrap;
	justify-content: center;
}
.navbar-nav.router > .nav-item,
.navbar-nav.settings > .nav-item {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.navbar-nav.router > .nav-item > .dropdown-menu,
.navbar-nav.settings > .nav-item > .dropdown-menu {
	position: absolute;
}
@media(min-width: 426px){
	.navbar-nav.settings {
		margin-right: 0 !important;
	}
	.navbar-nav.settings .dropdown-menu-left {
		right: 0;
		left: auto;
	}
	.navbar-nav.router {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}
</style>
