import dictionaries from './dict';
// import getLanguageAlias from './getLanguageAlias';

export default function getCurrentDictionary() {
	// var lang = getLanguageAlias();

	// if (dictionaries[lang]) {
	// 	return dictionaries[lang];
	// }

	return dictionaries.en;
}
