<template>
	<div v-if="firstVerifiedCheck">
		<login v-if="!isLoggedIn"/>
		<not-verified v-else-if="!isVerified"/>
		<company-chooser v-else-if="!transporterCode"/>
		<no-access v-else-if="!hasAccess"/>
		<keep-alive>
			<slot v-if="accessRequirementsMet" />
		</keep-alive>
	</div>
	<div v-else>
		Initializing
	</div>
</template>

<script>
import Login from './index.vue';
import NoAccess from './noAccess.vue';
import NotVerified from './notVerified.vue';
import CompanyChooser from './companyChooser.vue';

import { mapState, mapMutations, mapActions } from './store';

import {userAuth} from '@/core';

export default {
	components: {
		Login,
		NoAccess,
		NotVerified,
		CompanyChooser
	},
	data() {
		return {
			firstVerifiedCheck: false
		};
	},
	computed: {
		...mapState([
			'isLoggedIn',
			'isVerified',
			'hasAccess',
			'isSuper',
			'transporterCode'
		]),
		accessRequirementsMet() {
			return !!this.isLoggedIn && !!this.isVerified && !!this.transporterCode && !!this.hasAccess;
		}
	},
	methods: {
		...mapActions([
			'setUsersWithoutAccessInterval',
			'clearUsersWithoutAccessInterval',
			'userLastLogin',
			'setTransporter'
		]),
		...mapMutations([
			'setLoggedIn',
			'setIsVerified',
			'setHasAccess',
			'setIsSuper',
		]),
		async refreshAccess() {
			try {
				let { notLoggedIn, noAccess, notVerified, superUser } = await userAuth.verifyUserHasAccess();
				this.setLoggedIn(!notLoggedIn);
				this.setIsVerified(!notVerified);
				if(this.transporterCode){
					await this.setTransporter(this.transporterCode);
				}
				this.setHasAccess(!noAccess);
				if(!noAccess){
					this.setIsSuper(superUser);
				}
			} finally {
				this.firstVerifiedCheck = true;
			}
		}
	},
	watch: {
		isLoggedIn: function() {
			this.firstVerifiedCheck = false;
			this.refreshAccess();
		},
		isVerified: function() {
			this.firstVerifiedCheck = false;
			this.refreshAccess();
		},
		transporterCode: function() {
			this.firstVerifiedCheck = false;
			this.refreshAccess();
		},
		hasAccess: function() {
			this.userLastLogin();
		},
		isSuper(newValue, oldValue) {
			if(oldValue == newValue){
				return;
			}
			// if(newValue && !isDevelopment()){
			// 	this.setUsersWithoutAccessInterval();
			// } else {
			// 	this.clearUsersWithoutAccessInterval();
			// }
		}
	},
	mounted(){
		this.firstVerifiedCheck = false;
		this.refreshAccess();
	}
};
</script>

<style>

</style>
