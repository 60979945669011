<template>
	<b-container fluid>
		<em>Select company</em>
		<b-row style="margin-top:25px;" class="justify-content-md-center">
			<b-col cols="12" lg="6" xl="6">
				<b-list-group>
					<b-list-group-item href="#" v-for="company in companies" :key="company.id" :id="company.id" @click="setCompany(company.id)">
						<div class="d-flex w-100 justify-content-between">
							<h5 class="mb-1" v-text="company.displayName"></h5>
						</div>
					</b-list-group-item>
				</b-list-group>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import {mapState, mapActions} from './store';
export default {
	computed: {
		...mapState([
			'companies'
		]),
	},
	methods: {
		...mapActions([
			'setTransporter',
			'fetchCompanies',
		]),
		async setCompany(transporterCode){
			await this.setTransporter(transporterCode);
		}
	},
	async created(){
		await this.fetchCompanies();
	},
};
</script>
