import request from './request';
import publicRequest from './publicRequest';
import firebase from 'firebase/app';
import 'firebase/auth';

let c = {};

c.init = async function(config){
	firebase.initializeApp(config);
	c.initPromise = new Promise((resolve) => {
		let cancel = firebase.auth().onAuthStateChanged(async(user) => {
			cancel();
			resolve(user);
		});
	});
};

c.lastRequest = {validTo: 0, result: undefined};

c.signOut = async function() {
	await c.initPromise;
	await firebase.auth().signOut();
	c.lastRequest = {validTo: 0, result: undefined};
};

c.verifyUserHasAccess = async function(){
	await c.initPromise;
	if(await c.userNotLoggedIn()){
		return {notLoggedIn: true, notVerified: true, noAccess: true};
	} else if(await c.userNotVerifiedEmail()){
		return {notLoggedIn: false, notVerified: true, noAccess: true};
	} else if(await c.userNoAccess()){
		return {notLoggedIn: false, notVerified: false, noAccess: true};
	}
	return {notLoggedIn: false, notVerified: false, noAccess: false, superUser: c.lastRequest.result.super};
};

c.onUserStateChanged = async function(cb) {
	firebase.auth().onAuthStateChanged(async(user) => {
		cb(user);
	});
};

c.getCurrentUser = async function() {
	await c.initPromise;
	return firebase.auth().currentUser;
};

c.getIdToken = async function() {
	await c.initPromise;
	let user = firebase.auth().currentUser;
	if(!user) {
		return null;
	}
	let token = await user.getIdToken();
	return token;
};

c.userNotVerifiedEmail = async function() {
	await c.initPromise;
	firebase.auth().currentUser.reload();
	let user = firebase.auth().currentUser;
	if(user){
		return !user.emailVerified;
	} else {
		return null;
	}
};

c.userNoAccess = async function(noLog = false) {
	await c.initPromise;
	//result={super:true||false}||undefined
	//!undefined=true=>noAccess, !{super:true||false}=false=>Access
	let noAccess = !c.lastRequest.result;
	if(c.lastRequest.validTo < new Date().valueOf() || !c.lastRequest.result){
		let hasAccess = await request({
			method: 'get',
			url: `/users/currentUser/hasAccess?noLog=${noLog}`,
		}).catch(() => {
			//console.log(e.message); //Usually 'No Transportercode'
			return !noAccess;
		});
		noAccess = !hasAccess;
		c.lastRequest.result = hasAccess;
		c.lastRequest.validTo = new Date().valueOf() + 30000;
	}
	return noAccess;
};

c.userNotLoggedIn = async function() {
	await c.initPromise;
	let currentUser = await firebase.auth().currentUser;
	let notLoggedIn = true;
	if(currentUser !== undefined && currentUser !== null) {
		notLoggedIn = false;
	}
	return notLoggedIn;
};

c.sendEmailVerification = async function() {
	await c.initPromise;
	return await publicRequest({
		method: 'post',
		url: 'users/sendVerificationEmail'
	});
};

c.userLastLogin = async function(){
	await c.initPromise;
	let lastLogin = await request({
		method: 'get',
		url: '/users/currentUser/lastLogin'
	});
	return lastLogin;
};

c.updateLastLogin = async function() {
	await c.initPromise;
	await request({
		method: 'post',
		url: '/users/currentUser/lastLogin'
	});
};

c.auth = firebase.auth;

export default c;
