module.exports = [
	{
		text: 'GPC',
		value: 'GPC'
	},
	{
		text: 'OSCC',
		value: 'OSCC'
	},
	{
		text: 'COOL (Helsinki)',
		value: 'COOL (Helsinki)'
	},
	{
		text: 'Swissport',
		value: 'Swissport'
	},
	{
		text: 'Dnata',
		value: 'Dnata'
	},
	{
		text: 'WFS',
		value: 'WFS'
	},
	{
		text: 'Spirit',
		value: 'Spirit'
	},
	{
		text: 'Schenker LHR',
		value: 'Schenker LHR'
	},
	{
		text: 'IP Handlers (Alsmeer)',
		value: 'IP Handlers (Alsmeer)'
	},
];
