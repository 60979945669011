import getCurrentDictionary from './getCurrentDict';

export default function negotiateTranslatedText(value, key, optionalKeyVar) {
	if (!key) {
		return value || '';
	}

	var dictionary = getCurrentDictionary();
	var text = dictionary[key] || value || '';

	if (optionalKeyVar) {
		text = text.replace('%var%', optionalKeyVar);
	}

	return text;
}
