<template>
	<div>
		<div><em>You must verify your email address first</em></div>
		<div><span>Not recieved the email? Try sending again</span></div>
		<div><b-button @click="sendNewEmail">Send new verification email</b-button></div>
	</div>
</template>

<script>
import { userAuth } from '@/core';
import { mapMutations } from './store';

export default {
	name: 'notVerified',
	data() {
		return {
			intervalId: null,
		};
	},
	methods: {
		...mapMutations([
			'setIsVerified'
		]),
		async waitForUserVerification() {
			this.userVerified();
			this.intervalId = setInterval(() => this.userVerified(), 2500);
		},
		async userVerified(){
			let notVerified = await userAuth.userNotVerifiedEmail();
			this.setIsVerified(!notVerified);
			if(!notVerified){
				this.setIsVerified(!notVerified);
				clearInterval(this.intervalId);
				this.$router.replace({name:'orders'});
			}
		},
		async sendNewEmail() {
			await userAuth.sendEmailVerification();
		}
	},
	async mounted() {
		this.waitForUserVerification();
	},
	beforeDestroy() {
		clearInterval(this.intervalId);
	}
};
</script>

