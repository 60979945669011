import Vue from 'vue';
import Vuex from 'vuex';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

const state = {
	/**Note:
	 * Changed 'user' to 'currentUser' because "child stores" get saved as
	 * an object under the store module's namespace.
	 * I.e. when registering the store module 'user', it overwrites the root state-instance
	 * of user, which is supposed to be a simple user-object
	 */
	//Used for stackable modals
	modals: []
};

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters
});

export {
	mapState,
	mapGetters,
	mapMutations,
	mapActions
};
