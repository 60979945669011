import axios from 'axios';
import { baseUrl } from '@/core';

export default async function request(request) {
	const userAuth = require('./userAuth').default;

	let user = await userAuth.getCurrentUser();
	if(!user) throw new Error('User not logged in');

	let token = await user.getIdToken();

	delete request.baseUrl;
	var reqObj= {
		...request,
		baseURL: baseUrl(),
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};
	try {
		let res = await axios(reqObj);
		return res.data;
	} catch (e) {
		throw new Error(e);
	}
}
