import Vue from 'vue';

const setUser = function(state, payload){
	if(state.currentUser.uid == payload.uid){
		return;
	}
	state.currentUser = {
		uid: payload.uid,
		email: payload.email,
		displayName: payload.displayName,
		photoUrl: payload.photoURL,
	};
};
const clearUser = function(state) {
	state.currentUser = {
		uid: null,
		email: null,
		displayName: null,
		photoUrl: null,
	};
};
const setLoggedIn = function(state, payload) {
	state.isLoggedIn = payload;
};
const setHasAccess = function(state, payload){
	Vue.set(state, 'hasAccess', payload);
};
const setIsVerified = function(state, payload){
	state.isVerified = payload;
};
const setIsSuper = function(state, payload){
	state.isSuper = payload;
};
const setTransporterCode = function(state, payload) {
	state.transporterCode = payload;
	localStorage.setItem('transporterCode', payload);
};
const setTransporterSettings = function(state, payload) {
	state.transporterSettings = payload;
};
const setLastLogin = function(state, payload) {
	state.lastLogin = payload;
};
const setUsersWithoutAccessIntervalId = function( state, payload){
	state.updateUsersWithoutAccessIntervalId = payload;
};

const setUsersWithoutAccess = function(state, payload){
	state.usersWithoutAccess = payload;
};

const setUsersWithAccess = function(state, payload){
	state.usersWithAccess = payload;
};

const setBannedUsers = function(state, payload){
	state.bannedUsers = payload;
};

const setSuperUsers = function(state, payload){
	state.superUsers = payload;
};

const movetoUsersWithAccess = function(state, payload){
	for(let userId of payload){
		let i = state.usersWithoutAccess.findIndex(user => user.id === userId);
		let user = state.usersWithoutAccess.splice(i, 1);
		state.usersWithAccess.push(user[0]);
	}
};

const movetoUsersWithoutAccess = function(state, payload){
	for(let userId of payload){
		let i = state.usersWithAccess.findIndex(user => user.id === userId);
		let user = state.usersWithAccess.splice(i,1);
		state.usersWithoutAccess.push(user[0]);
	}
};

const movetoBannedUsers = function(state, payload){
	for(let userId of payload){
		let i = state.usersWithoutAccess.findIndex(user => user.id === userId);
		let user = state.usersWithoutAccess.splice(i, 1);
		state.bannedUsers.push(user[0]);
	}
};

const movetoUsersWithoutAccessFromBanned = function(state, payload){
	for(let userId of payload){
		let i = state.bannedUsers.findIndex(user => user.id === userId);
		let user = state.bannedUsers.splice(i,1);
		state.usersWithoutAccess.push(user[0]);
	}
};

const removeUserFromWithoutAccess = function(state, payload){
	for(let userId of payload){
		let i = state.usersWithoutAccess.findIndex(user => user.id === userId);
		state.usersWithoutAccess.splice(i, 1);
	}
};

const movetoSuperusers = function(state, userId){
	let i = state.usersWithAccess.findIndex(user => user.id === userId);
	let user = state.usersWithAccess.splice(i, 1);
	state.superUsers.push(user[0]);
};

const movefromSuperusers = function(state, userId){
	let i = state.superUsers.findIndex(user => user.id === userId);
	let user = state.superUsers.splice(i, 1);
	state.usersWithAccess.push(user[0]);
};

const setUserOrderHandler = function(state, userId){
	var i = state.usersWithAccess.findIndex(user => user.id === userId);
	if(i >= 0) {
		Vue.set(state.usersWithAccess[i], 'orderHandler', true);
	} else {
		i = state.superUsers.findIndex(user => user.id === userId);
		Vue.set(state.superUsers[i], 'orderHandler', true);
	}
};

const removeUserOrderHandler = function(state, userId){
	var i = state.usersWithAccess.findIndex(user => user.id === userId);
	if(i >= 0) {
		Vue.set(state.usersWithAccess[i], 'orderHandler', false);
	} else {
		i = state.superUsers.findIndex(user => user.id === userId);
		Vue.set(state.superUsers[i], 'orderHandler', false);
	}
};

const setCompanies = function(state, payload){
	state.companies = payload;
};

const clearUserDefinedColumnDefinitions = () => {
	localStorage.removeItem('colDef');
	localStorage.removeItem('colDefSort');
	localStorage.removeItem('colDefTtl');
	localStorage.removeItem('colDefOC');
	localStorage.removeItem('colDefOCSort');
	localStorage.removeItem('colDefOCTtl');
};

const clearTransporterCode = () => {
	localStorage.removeItem('transporterCode');
};

export default {
	setUser,
	clearUser,
	setLoggedIn,
	setHasAccess,
	setIsVerified,
	setIsSuper,
	setTransporterCode,
	setTransporterSettings,
	setLastLogin,
	setUsersWithoutAccessIntervalId,
	setUsersWithoutAccess,
	setUsersWithAccess,
	setBannedUsers,
	setSuperUsers,
	movetoUsersWithAccess,
	movetoUsersWithoutAccess,
	movetoBannedUsers,
	movetoUsersWithoutAccessFromBanned,
	removeUserFromWithoutAccess,
	movetoSuperusers,
	movefromSuperusers,
	setCompanies,
	clearUserDefinedColumnDefinitions,
	clearTransporterCode,
	setUserOrderHandler,
	removeUserOrderHandler,
};
