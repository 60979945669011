module.exports = [
	{type: 'AW', description: 'Air Waybill'},
	{type: 'BL', description: 'Bill of Lading'},
	{type: 'CM', description: 'CMR'},
	{type: 'FR', description: 'Norsk Fraktbrev'},
	{type: 'HC', description: 'Health Certificate'},
	{type: 'HF', description: 'Commercial Invoice'},
	{type: 'LA', description: 'Loading List'},
	{type: 'OD', description: 'Other Document'},
	{type: 'OR', description: 'Certificate of Origin'},
	{type: 'PI', description: 'Picture of Damage'},
	{type: 'PL', description: 'Packing List'},
	{type: 'PP', description: 'Picture'},
	{type: 'SA', description: 'Single Administrative Document'},
	{type: 'TL', description: 'Temperature Log'},
];
