<template>
	<div>
		<h3 v-if="userNotLoggedIn"> You're not logged in </h3>
		<div id="firebaseui-auth-container"></div>
	</div>
</template>

<script>
// @ is an alias to /src
import 'firebaseui/dist/firebaseui.css';
import firebaseui from 'firebaseui';
import { userAuth } from '@/core';
import { mapState, mapActions } from './store';

export default {
	name: 'login',
	data() {
		return {
			userNotLoggedIn: false,
		};
	},
	mounted() {
		var self = this;
		const uiConfig = {
			signInSuccessUrl: '/',
			callbacks: {
				signInSuccessWithAuthResult(authResult) {
					setTimeout(() => self.onLogin(authResult), 0);
					return false;
				}
			},
			signInFlow: 'popup',
			signInOptions: [
				userAuth.auth.EmailAuthProvider.PROVIDER_ID
			],
			credentialHelper: firebaseui.auth.CredentialHelper.NONE
		};
		let ui = firebaseui.auth.AuthUI.getInstance();
		if(!ui) {
			ui= new firebaseui.auth.AuthUI(userAuth.auth());
		}
		ui.start('#firebaseui-auth-container', uiConfig);
	},
	methods: {
		...mapActions([
			'setUsersWithoutAccessInterval'
		]),
		async onLogin(authResult) {
			// console.log(authResult);
			let user = authResult.user;
			if(authResult.additionalUserInfo.isNewUser){
				// let userId = user.uid;
				await userAuth.sendEmailVerification(user);
			}
		}
	},
	computed: {
		...mapState([
			'isLoggedIn',
		])
	},
	beforeRouteLeave(to, from, next) {
		if((to.meta && to.meta.public) || this.isLoggedIn){
			next();
		} else {
			next(false);
			this.userNotLoggedIn = true;
		}
	}
};
</script>
