module.exports = [
	{
		'tollst': '0101',
		'tollsted': 'Oslo'
	},
	{
		'tollst': '0102',
		'tollsted': 'Gardermoen'
	},
	{
		'tollst': '0201',
		'tollsted': 'Fredrikstad/Svinesund'
	},
	{
		'tollst': '0206',
		'tollsted': 'Ørje'
	},
	{
		'tollst': '0208',
		'tollsted': 'Hån'
	},
	{
		'tollst': '0209',
		'tollsted': 'Svinesund, Svensk'
	},
	{
		'tollst': '0210',
		'tollsted': 'Kongsvinger'
	},
	{
		'tollst': '0211',
		'tollsted': 'Hamar'
	},
	{
		'tollst': '0212',
		'tollsted': 'Østby'
	},
	{
		'tollst': '0213',
		'tollsted': 'Åsnes'
	},
	{
		'tollst': '0214',
		'tollsted': 'Eda'
	},
	{
		'tollst': '0215',
		'tollsted': 'Idre'
	},
	{
		'tollst': '0601',
		'tollsted': 'Kristiansand'
	},
	{
		'tollst': '0610',
		'tollsted': 'Drammen'
	},
	{
		'tollst': '0611',
		'tollsted': 'Grenland'
	},
	{
		'tollst': '0612',
		'tollsted': 'Sandefjord'
	},
	{
		'tollst': '0801',
		'tollsted': 'Bergen'
	},
	{
		'tollst': '0807',
		'tollsted': 'Stavanger'
	},
	{
		'tollst': '0808',
		'tollsted': 'Haugesund'
	},
	{
		'tollst': '0809',
		'tollsted': 'Ålesund'
	},
	{
		'tollst': '0901',
		'tollsted': 'Trondheim'
	},
	{
		'tollst': '0910',
		'tollsted': 'Vauldalen'
	},
	{
		'tollst': '0916',
		'tollsted': 'Storlien'
	},
	{
		'tollst': '0918',
		'tollsted': 'Bodø'
	},
	{
		'tollst': '0919',
		'tollsted': 'Junkerdalen'
	},
	{
		'tollst': '0920',
		'tollsted': 'Mo'
	},
	{
		'tollst': '0921',
		'tollsted': 'Narvik/Bjørnfjell'
	},
	{
		'tollst': '0923',
		'tollsted': 'Tärnaby'
	},
	{
		'tollst': '1101',
		'tollsted': 'Tromsø'
	},
	{
		'tollst': '1103',
		'tollsted': 'Hammerfest'
	},
	{
		'tollst': '1104',
		'tollsted': 'Harstad'
	},
	{
		'tollst': '1105',
		'tollsted': 'Helligskogen'
	},
	{
		'tollst': '1107',
		'tollsted': 'Kirkenes'
	},
	{
		'tollst': '1109',
		'tollsted': 'Polmak'
	},
	{
		'tollst': '1111',
		'tollsted': 'Karigasniemi'
	},
	{
		'tollst': '1112',
		'tollsted': 'Kivilompolo'
	},
	{
		'tollst': '1114',
		'tollsted': 'Utsjok'
	}
].map(x => ({tollst: x.tollst, tollsted: x.tollsted, disabled:true}))
	.concat(
		{
			'tollst': '3210',
			'tollsted': 'Tromsø'
		},
		{
			'tollst': '3220',
			'tollsted': 'Hammerfest'
		},
		{
			'tollst': '3230',
			'tollsted': 'Neiden'
		},
		{
			'tollst': '3240',
			'tollsted': 'Kirkenes / Storskog'
		},
		{
			'tollst': '3250',
			'tollsted': 'Polmak'
		},
		{
			'tollst': '3260',
			'tollsted': 'Karigasiniemi FI'
		},
		{
			'tollst': '3270',
			'tollsted': 'Kivilompolo FI'
		},
		{
			'tollst': '3280',
			'tollsted': 'Utsjok FI'
		},
		{
			'tollst': '3290',
			'tollsted': 'Helligskogen'
		},
		{
			'tollst': '3310',
			'tollsted': 'Bjørnfjell'
		},
		{
			'tollst': '3320',
			'tollsted': 'Bodø'
		},
		{
			'tollst': '3330',
			'tollsted': 'Trondheim'
		},
		{
			'tollst': '3340',
			'tollsted': 'Vauldalen'
		},
		{
			'tollst': '3350',
			'tollsted': 'Storlien SE'
		},
		{
			'tollst': '3360',
			'tollsted': 'Tärnaby SE'
		},
		{
			'tollst': '3370',
			'tollsted': 'Junkerdalen'
		},
		{
			'tollst': '3380',
			'tollsted': 'Mo'
		},
		{
			'tollst': '3410',
			'tollsted': 'Ålesund'
		},
		{
			'tollst': '3420',
			'tollsted': 'Bergen'
		},
		{
			'tollst': '3430',
			'tollsted': 'Flesland'
		},
		{
			'tollst': '3440',
			'tollsted': 'Haugesund'
		},
		{
			'tollst': '3450',
			'tollsted': 'Sola / Stavanger'
		},
		{
			'tollst': '3510',
			'tollsted': 'Gardermoen'
		},
		{
			'tollst': '3610',
			'tollsted': 'Kristiansand'
		},
		{
			'tollst': '3620',
			'tollsted': 'Grenland'
		},
		{
			'tollst': '3630',
			'tollsted': 'Torp'
		},
		{
			'tollst': '3640',
			'tollsted': 'Sandefjord'
		},
		{
			'tollst': '3650',
			'tollsted': 'Oslo'
		},
		{
			'tollst': '3680',
			'tollsted': 'Drammen'
		},
		{
			'tollst': '3730',
			'tollsted': 'Kongsvinger / Åsnes'
		},
		{
			'tollst': '3740',
			'tollsted': 'Østby'
		},
		{
			'tollst': '3750',
			'tollsted': 'Eda SE'
		},
		{
			'tollst': '3760',
			'tollsted': 'Idre SE'
		},
		{
			'tollst': '3770',
			'tollsted': 'Svinesund SE'
		},
		{
			'tollst': '3780',
			'tollsted': 'Hån SE'
		}
	)
;
