import axios from 'axios';
import { publicBaseUrl } from '@/core';

export default async function publicRequest(request) {
	const userAuth = require('./userAuth').default;
	
	let user = await userAuth.getCurrentUser();
	let token = user ? await user.getIdToken() : '';

	delete request.baseUrl;
	var reqObj= {
		...request,
		baseURL: publicBaseUrl(),
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};
	try {
		let res = await axios(reqObj);
		return res.data;
	} catch (e) {
		throw new Error(e);
	}
}
