module.exports = [
	{
		'region': 'Oslo og Akershus',
		'type': 'Import',
		'tollsteder': [
			{'tollst': '10102','tollsted': 'TV Oslo'},
			{'tollst': '10104','tollsted': 'GK Oslo direktefortolling'},
			{'tollst': '10105','tollsted': 'GK Oslo'},
			{'tollst': '10107','tollsted': 'TV Oslo ereg'},
			{'tollst': '10108','tollsted': 'TV Alnabru'},
			{'tollst': '10109','tollsted': 'TV Alnabru'},
			{'tollst': '10111','tollsted': 'TV Oslo'},
			{'tollst': '10118','tollsted': 'TV Alnabru ereg'},
			{'tollst': '10131','tollsted': 'Regel.Avd,Omberegningsseksjonen'},
			{'tollst': '10136','tollsted': 'TV Oslo, bruktbil'},
			{'tollst': '10137','tollsted': 'Regel.Avd,Tariff- og tollverdiseksj'},
			{'tollst': '10150','tollsted': 'TV Samordningskontoret (SAM)'},
			{'tollst': '10160','tollsted': 'TVINN-Internett'},
			{'tollst': '10171','tollsted': 'Omberegningsseksjonen, el.omb'},
			{'tollst': '10173','tollsted': 'Omberegningsseksjonen, el.omb, SO'},
			{'tollst': '10175','tollsted': 'Omberegningsseksjonen, el.omb, auto'},
			{'tollst': '10201','tollsted': 'TV Gardermoen'},
			{'tollst': '10203','tollsted': 'TV Gardermoen'},
			{'tollst': '10204','tollsted': 'GK Gardermoen direktefortolling'},
			{'tollst': '10205','tollsted': 'TV Gardermoen'},
			{'tollst': '10206','tollsted': 'TV Gardermoen ereg'},
			{'tollst': '10207','tollsted': 'TV Gardermoen'},
			{'tollst': '10208','tollsted': 'TV Gardermoen'},
		]
	},
	{
		'region': 'Øst-Norge',
		'type': 'Import',
		'tollsteder': [
			{'tollst': '20101', 'tollsted': 'TV Fredrikstad/Svinesund Omberegnin'},
			{'tollst': '20102', 'tollsted': 'TV Fredrikstad Ekspedisjon'},
			{'tollst': '20104', 'tollsted': 'TV Fredrikstad godsregistrerte'},
			{'tollst': '20105', 'tollsted': 'GK Fredrikstad'},
			{'tollst': '20110', 'tollsted': 'TV Svinesund direktefortolling'},
			{'tollst': '20601', 'tollsted': 'TV Ørje direktefortolling'},
			{'tollst': '20602', 'tollsted': 'TV Ørje godsregistrerte'},
			{'tollst': '21001', 'tollsted': 'TV Kongsvinger ekspedisjon'},
			{'tollst': '21050', 'tollsted': 'TV Kongsvinger el.omberegning'},
			{'tollst': '21201', 'tollsted': 'GK Østby direktefortolling'},
			{'tollst': '21202', 'tollsted': 'GK Østby godsregistrerte'},
			{'tollst': '21301', 'tollsted': 'GK Åsnes direktefortolling'},
			{'tollst': '21401', 'tollsted': 'TV Eda SE direktefortolling'},
			{'tollst': '21501', 'tollsted': 'GK Idre SE direktefortolling'},
		]
	},

	{
		'region':'Sør-Norge',
		'type': 'Import',
		'tollsteder': [
			{'tollst': '60101', 'tollsted': 'TV Krsand ereg / direktefortolling'},
			{'tollst': '61001', 'tollsted': 'TV Drammen deklarasjonskontroll'},
			{'tollst': '61002', 'tollsted': 'TV Drammen ereg / direktefortolling'},
			{'tollst': '61011', 'tollsted': 'TV Drammen el.omberegning'},
			{'tollst': '61013', 'tollsted': 'TV Drammen el.omberegning'},
			{'tollst': '61101', 'tollsted': 'TV Grenland ereg /direktefortolling'},
			{'tollst': '61201', 'tollsted': 'TV Sandefjord ereg/direktefortollin'},
			{'tollst': '61212', 'tollsted': 'GK Larvik ereg / direktefortolling'},
		]
	},
	{
		'region':'Vest-Norge',
		'type': 'Import',
		'tollsteder': [
			{'tollst': '80101', 'tollsted': 'TV Bergen deklarasjonskontroll'},
			{'tollst': '80111', 'tollsted': 'TV Bergen el.omberegning'},
			{'tollst': '80701', 'tollsted': 'TV Stavanger deklarasjonskontroll'},
			{'tollst': '80801', 'tollsted': 'GK Haugesund'},
			{'tollst': '80901', 'tollsted': 'GK Ålesund'},
		]
	},
	{
		'region':'Midt-Norge',
		'type': 'Import',
		'tollsteder': [
			{'tollst': '90101', 'tollsted': 'TV Trondheim'},
			{'tollst': '90110', 'tollsted': 'TV Trondheim el.omberegning'},
			{'tollst': '91001', 'tollsted': 'GK Vauldalen'},
			{'tollst': '91601', 'tollsted': 'GK Storlien'},
			{'tollst': '91801', 'tollsted': 'GK Bodø'},
			{'tollst': '91901', 'tollsted': 'GK Junkerdalen'},
			{'tollst': '92001', 'tollsted': 'GK Mo'},
			{'tollst': '92101', 'tollsted': 'GK Narvik'},
			{'tollst': '92118', 'tollsted': 'GK Bjørnfjell'},
			{'tollst': '92301', 'tollsted': 'GK Tärnaby'},
		]
	},
	{
		'region':'Nord-Norge',
		'type': 'Import',
		'tollsteder': [
			{'tollst': '110101', 'tollsted': 'TV Tromsø'},
			{'tollst': '110110', 'tollsted': 'TV Tromsø el.omberegning'},
			{'tollst': '110301', 'tollsted': 'GK Hammerfest'},
			{'tollst': '110401', 'tollsted': 'GK Harstad'},
			{'tollst': '110501', 'tollsted': 'GK Helligskogen'},
			{'tollst': '110701', 'tollsted': 'GK Kirkenes'},
			{'tollst': '110708', 'tollsted': 'GK Neiden'},
			{'tollst': '110709', 'tollsted': 'GK Storskog'},
			{'tollst': '110901', 'tollsted': 'GK Polmak'},
			{'tollst': '111101', 'tollsted': 'GK Karigasniemi'},
			{'tollst': '111201', 'tollsted': 'GK Kivilompolo'},
			{'tollst': '111401', 'tollsted': 'GK Utsjok'},
		]
	},
	{
		'region':'Oslo og Akershus',
		'type': 'Export',
		'tollsteder': [
			{'tollst': '10120', 'tollsted': 'TV Alnabru ereg'},
			{'tollst': '10121', 'tollsted': 'TV Oslo'},
			{'tollst': '10122', 'tollsted': 'TV Oslo'},
			{'tollst': '10125', 'tollsted': 'TV Alnabru'},
			{'tollst': '10126', 'tollsted': 'Regel.Avd,Omberegningsseksjonen'},
			{'tollst': '10127', 'tollsted': 'GK Oslo'},
			{'tollst': '10172', 'tollsted': 'Omberegningsseksjonen, el.omb'},
			{'tollst': '10174', 'tollsted': 'Omberegningsseksjonen, el.omb, SO'},
			{'tollst': '10242', 'tollsted': 'TV Gardermoen ereg'},
			{'tollst': '10290', 'tollsted': 'TV Gardermoen'},
			{'tollst': '10291', 'tollsted': 'GK Gardermoen'},
		]
	},
	{
		'region':'Øst-Norge',
		'type': 'Export',
		'tollsteder': [
			{'tollst': '20190', 'tollsted': 'TV Fredrikstad'},
			{'tollst': '20690', 'tollsted': 'TV Ørje'},
			{'tollst': '20890', 'tollsted': 'TV Hån'},
			{'tollst': '20990', 'tollsted': 'TV Svinesund SE'},
			{'tollst': '21090', 'tollsted': 'TV Kongvinger'},
			{'tollst': '21095', 'tollsted': 'TV Kongsvinger el.omberegning'},
			{'tollst': '21290', 'tollsted': 'GK Østby'},
			{'tollst': '21390', 'tollsted': 'GK Åsnes'},
			{'tollst': '21490', 'tollsted': 'TV Eda SE'},
			{'tollst': '21590', 'tollsted': 'GK Idre SE'},
		]
	},
	{
		'region':'Sør-Norge',
		'type': 'Export',
		'tollsteder': [
			{'tollst': '60102', 'tollsted': 'TV Krsand ereg'},
			{'tollst': '61003', 'tollsted': 'TV Drammen ereg'},
			{'tollst': '61012', 'tollsted': 'TV Drammen el.omberegning'},
			{'tollst': '61090', 'tollsted': 'TV Drammen deklarasjonskontroll'},
			{'tollst': '61102', 'tollsted': 'TV Grenland ereg'},
			{'tollst': '61202', 'tollsted': 'TV Sandefjord ereg'},
			{'tollst': '61213', 'tollsted': 'GK Larvik ereg'},
		]
	},
	{
		'region':'Vest-Norge',
		'type': 'Export',
		'tollsteder': [
			{'tollst': '80110', 'tollsted': 'TV Bergen el.omberegning'},
			{'tollst': '80190', 'tollsted': 'TV Bergen deklarasjonskontroll'},
			{'tollst': '80790', 'tollsted': 'TV Stavanger deklarasjonskontroll'},
			{'tollst': '80890', 'tollsted': 'GK Haugesund'},
			{'tollst': '80990', 'tollsted': 'GK Ålesund'},
		]
	},
	{
		'region':'Midt-Norge',
		'type': 'Export',
		'tollsteder': [
			{'tollst': '90111', 'tollsted': 'TV Trondheim el.omberegning'},
			{'tollst': '90190', 'tollsted': 'TV Trondheim'},
			{'tollst': '91090', 'tollsted': 'GK Vauldalen'},
			{'tollst': '91690', 'tollsted': 'GK Storlien'},
			{'tollst': '91891', 'tollsted': 'GK Bodø'},
			{'tollst': '91990', 'tollsted': 'GK Junkerdalen'},
			{'tollst': '92090', 'tollsted': 'GK Mo'},
			{'tollst': '92190', 'tollsted': 'GK Narvik'},
			{'tollst': '92191', 'tollsted': 'GK Bjørnfjell'},
			{'tollst': '92390', 'tollsted': 'GK Tärnaby'},
		]
	},
	{
		'region':'Nord-Norge',
		'type': 'Export',
		'tollsteder': [
			{'tollst': '110111', 'tollsted': 'TV Tromsø el.omberegning'},
			{'tollst': '110190', 'tollsted': 'TV Tromsø'},
			{'tollst': '110390', 'tollsted': 'GK Hammerfest'},
			{'tollst': '110490', 'tollsted': 'GK Harstad'},
			{'tollst': '110590', 'tollsted': 'GK Helligskogen'},
			{'tollst': '110790', 'tollsted': 'GK Kirkenes'},
			{'tollst': '110791', 'tollsted': 'GK Neiden'},
			{'tollst': '110793', 'tollsted': 'GK Storskog'},
			{'tollst': '110990', 'tollsted': 'GK Polmak'},
			{'tollst': '111190', 'tollsted': 'GK Karigasniemi'},
			{'tollst': '111290', 'tollsted': 'GK Kivilompolo'},
			{'tollst': '111490', 'tollsted': 'GK Utsjok'},
		]
	},
];
