
export default {
	//Used for stackable modals
	appendModalToStack(state, modal) {
		state.modals.push(modal);
	},
	removeModalFromStack(state, id) {
		for(let i = state.modals.length - 1; i >= 0; i--) {
			if(state.modals[i].id !== id) continue;
			state.modals.splice(i, 1);
			return;
		}
	},
	/////////////////////////////////////////////
};
