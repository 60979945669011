<template>
	<b-nav-item-dropdown
		:text="companyName"
		class="companyCombobox"
	>
		<b-dropdown-item
			v-for="company in companies"
			:key="company.id"
			:value="company.id"
			@click="setCompany(company.id)"
		>{{ company.displayName }}</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
import {mapState, mapMutations, mapActions} from './store';
export default {
	computed: {
		...mapState([
			'companies',
			'transporterCode'
		]),
		companyName() {
			let company = this.companies.filter(company => {return company.id == this.transporterCode;})[0];
			if(!company) return '';
			let name = company.displayName;
			return name;
		}
	},
	methods: {
		...mapMutations([
			'setHasAccess',
			'setIsSuper',
		]),
		...mapActions([
			'setTransporter',
			'fetchCompanies',
			'clearUserSettings'
		]),
		async setCompany(transporterCode){
			this.setHasAccess(false);
			this.setIsSuper(false);
			await this.setTransporter(transporterCode);
			this.clearUserSettings();
			location.reload();
		}
	},
	async created(){
		await this.fetchCompanies();
	},
};
</script>

<style lang="scss" scoped>
	.companyCombobox.dropdown /deep/ .dropdown-menu {
		max-height: 300px;
		overflow-y: auto;
	}
</style>
