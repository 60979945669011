const statusValues = require('./orderStatusValues');

var validate = function(order, validators) {
	if(!validators) return false; // Just if validators undefined. Empty array allowed
	let res = true;
	for(let validator of validators){
		if(!validatorFunctions[validator](order)){
			res = false;
			break;
		}
	}
	return res;
};

var validatorFunctions = {
	never: function() {
		return false;
	},
	hasAtd: function(order) {
		return Boolean(order.atd.date);
	},
	hasAta: function(order) {
		return Boolean(order.ata.date);
	},
	hasAwb: function(order) {
		return Boolean(order.awbReference);
	},
	hasCustomsCrossing: function(order) {
		return Boolean(order.customsCrossingPoint);
	},
	hasVehicleLicense: function(order) {
		let licensePlate = Boolean(order.vehicleLicensePlate);
		let nationality = Boolean(order.vehicleNationality);
		return Boolean(licensePlate && nationality);
	},
	hasVehicleType: function(order) {
		return Boolean(order.vehicleType);
	},
	hasVehicleDriver: function(order) {
		return Boolean(order.vehicleDriverName);
	},
	orderIsAccepted: function(order) {
		return Boolean(order.status >= statusValues.accepted);
	},
	orderIsNotAccepted: function(order) {
		return Boolean(order.status < statusValues.accepted);
	},
	orderIsApproved: function(order) {
		return Boolean(order.status >= statusValues.approved);
	},
	orderIsNotApproved: function(order) {
		return Boolean(order.status < statusValues.approved);
	},
	orderIsFinalized: function(order) {
		return Boolean(order.status >= statusValues.delivered);
	},
	orderIsNotFinalized: function(order) {
		return Boolean(order.status < statusValues.delivered);
	},
	orderIsSent: function(order) {
		return Boolean(order.orderSent === true);
	},
	orderIsNotSent: function(order) {
		return Boolean(order.orderSent !== true);
	},
	hasInterchangeId: function(order) {
		return Boolean((order.edi || {}).interchangeId);
	},
	isSentByEdi: function(order) { // Transport need
		return Boolean(!!(order.ediSent) === false);
	},
	isNotSentByEdi: function(order) { // Transport need
		return Boolean(!!(order.ediSent) === true);
	},
	
};

module.exports = {
	validate,
	validatorFunctions
};
