module.exports = [
	{
		description: 'Actual Weight',
		type: 'weight',
		applicable: () => {
			return true;
		},
		divider: (order, orderCollection) => {
			let totalWeight = orderCollection.orders.reduce((sum, next) => { return sum += next.freightLinesGrossWeight;}, 0);
			if(!totalWeight) return 0;
			let orderWeight = order.freightLinesGrossWeight;
			return orderWeight / totalWeight;
		}
	},
	{
		description: 'Actual Packages',
		type: 'packages',
		applicable: () => {
			return true;
		},
		divider: (order, orderCollection) => {
			let totalPackages = orderCollection.orders.reduce((sum, next) => { return sum += next.freightLinesQuantity;}, 0);
			if(!totalPackages) return 0;
			let orderPackages = order.freightLinesQuantity;
			return orderPackages / totalPackages;
		}
	},
	{
		description: 'Estimated Weight',
		type: 'estWeight',
		applicable: () => {
			return true;
		},
		divider: (order, orderCollection) => {
			let totalWeight = orderCollection.grossWeight;
			if(!totalWeight) return 0;
			let orderWeight = order.freightLinesGrossWeight;
			return orderWeight / totalWeight;
		}
	},
	{
		description: 'Estimated Packages',
		type: 'estPackages',
		applicable: () => {
			return true;
		},
		divider: (order, orderCollection) => {
			let totalPackages = orderCollection.numberOfPackages;
			if(!totalPackages) return 0;
			let orderPackages = order.freightLinesQuantity;
			return orderPackages / totalPackages;
		}
	},
	{
		description: 'Per weight',
		type: 'perWeight',
		unit: 'KGM',
		applicable: () => {
			return true;
		},
		divider: (order) => {
			let orderWeight = order.freightLinesGrossWeight;
			return orderWeight || 0;
		}
	},
	{
		description: 'Per package',
		type: 'perPackage',
		unit: 'DPAK',
		applicable: () => {
			return true;
		},
		divider: (order) => {
			let orderPackages = order.freightLinesQuantity;
			return orderPackages || 0;
		}
	},
	{
		description: 'On each order',
		type: 'each',
		unit: 'ORDR',
		applicable: () => {
			return true;
		},
		divider: () => {
			return 1;
		}
	},
	{
		description: 'Divided between orders',
		type: 'divided',
		applicable: () => {
			return true;
		},
		divider: (order, orderCollection) => {
			return 1 / orderCollection.orders.length;
		}
	},
];
