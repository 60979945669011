module.exports = [
	{
		description: 'Per weight',
		type: 'perWeight',
		unit: 'KGM',
		applicable: () => {
			return true;
		},
		multiplicator: (order) => {
			let orderWeight = order.freightLinesGrossWeight;
			return orderWeight || 0;
		}
	},
	{
		description: 'Per package',
		type: 'perPackage',
		unit: 'DPAK',
		applicable: () => {
			return true;
		},
		multiplicator: (order) => {
			let orderPackages = order.freightLinesQuantity;
			return orderPackages || 0;
		}
	},
];
